<template>
  <div>

    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      height="550"
    />

    <!-- Parte Español -->
    <div
      v-if="language === 'es'"
      class="container my-5"
    >

      <!-- Title -->
      <h1 class="text-center mb-4">
        {{ $t('equality.title1') }}
      </h1>

      <p> {{ $t('equality.text1') }} </p>

      <p> {{ $t('equality.text2') }} </p>

      <h2 class="mt-5">
        {{ $t('equality.title2') }}
      </h2>

      <p> {{ $t('equality.text3') }} </p>

      <h2 class="mt-5">
        {{ $t('equality.title3') }}
      </h2>

      <p> {{ $t('equality.text4') }} </p>

      <p> {{ $t('equality.text5') }} </p>

      <p> {{ $t('equality.text6') }} </p>

      <p> {{ $t('equality.text7') }} </p>

      <ol>

        <li> {{ $t('equality.subtext1') }} </li>

        <li> {{ $t('equality.subtext2') }} </li>

        <li> {{ $t('equality.subtext3') }} </li>

        <li> {{ $t('equality.subtext4') }} </li>

        <li> {{ $t('equality.subtext5') }} </li>

        <li> {{ $t('equality.subtext6') }} </li>

      </ol>

      <p> {{ $t('equality.text8') }} </p>

      <ol>

        <li> {{ $t('equality.subtext7') }} </li>

        <li> {{ $t('equality.subtext8') }} </li>

        <li> {{ $t('equality.subtext9') }} </li>

        <li> {{ $t('equality.subtext10') }} </li>

        <li> {{ $t('equality.subtext11') }} </li>

        <li> {{ $t('equality.subtext12') }} </li>

      </ol>

      <p> {{ $t('equality.text9') }} </p>

      <p> {{ $t('equality.text10') }} </p>

      <p> {{ $t('equality.text11') }} </p>

      <p> {{ $t('equality.text12') }} </p>

      <p> {{ $t('equality.text13') }} </p>
    </div>

    <!-- Parte Inglés -->
    <div
      v-if="language === 'en'"
      class="container my-5"
    >

      <!-- Title -->
      <h1 class="text-center mb-4">
        {{ $t('equality.title1') }}
      </h1>

      <p> {{ $t('equality.text1') }} </p>

      <p> {{ $t('equality.text2') }} </p>

      <p> {{ $t('equality.text3') }} </p>

      <p> {{ $t('equality.text4') }} </p>

      <p> {{ $t('equality.text5') }} </p>

      <p> {{ $t('equality.text6') }} </p>

      <h2 class="mt-5">
        {{ $t('equality.title2') }}
      </h2>

      <p> {{ $t('equality.text7') }} </p>

      <p> {{ $t('equality.text8') }} </p>

      <h2 class="mt-5">
        {{ $t('equality.title3') }}
      </h2>

      <p> {{ $t('equality.text9') }} </p>

      <p> {{ $t('equality.text10') }} </p>

      <ol>

        <li> {{ $t('equality.subtext1') }} </li>

        <li> {{ $t('equality.subtext2') }} </li>

        <li> {{ $t('equality.subtext3') }} </li>

        <li> {{ $t('equality.subtext4') }} </li>

        <li> {{ $t('equality.subtext5') }} </li>

        <li> {{ $t('equality.subtext6') }} </li>

        <li> {{ $t('equality.subtext7') }} </li>

        <li> {{ $t('equality.subtext8') }} </li>

      </ol>
    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'

export default {
  name: 'Equality',
  components: {
    Banner,
  },
  data() {
    return {
      bannerImage: '/static/limmat-images/commitments/igualdad.jpg',
      language: null,
    }
  },
  mounted() {
    this.language = this.getLanguage()
  },
  methods: {
    getLanguage() {
      return localStorage.getItem('lang')
    },
  },
}
</script>

<style scoped>
  h2 {
    color: #ff8f00 !important;
    font-size: 30px !important;
    text-transform: uppercase !important;
  }
</style>
